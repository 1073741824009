import {
  Text,
  Stack,
  Group,
  Button,
  NumberInput,
  Title,
  Table,
  Tooltip,
  Slider,
  Badge,
} from "@mantine/core";
import { FC, useMemo, useState } from "react";
import { ProductVariant, VariantWithRatio } from "../../types";
import { ProductsQuery } from "../../gql/graphql";
import { productsToVariants } from "./utils";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type Props = {
  selected: boolean;
  products: ProductsQuery["products"];
  onInvestClick: (variants: VariantWithRatio[]) => () => void;
  investmentGoal: number;
  investedAmount: number;
};

const CustomInvestmentForm: FC<Props> = ({
  // selected,
  products,
  onInvestClick,
  // investmentGoal,
  // investedAmount,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "campaign.customInvestmentForm",
  });

  const [customAmount, setCustomAmount] = useState<string | number>();
  const [customProducts] = useState<ProductVariant[]>(
    productsToVariants(products)
  );

  const [error, setError] = useState<string>();

  //const [type, setType] = useState("auto");

  const onAmountChange = (newAmount: string | number) => {
    setError(undefined);
    setCustomAmount(newAmount);
  };

  const initialRatios = (() => {
    const itemCount = customProducts.length;
    const baseRatio = Math.floor(100 / itemCount / 5) * 5; // Ensures it's a multiple of 5
    const totalBaseRatio = baseRatio * itemCount;
    const remainder = 100 - totalBaseRatio;

    // Distribute the base ratio to each item and add the remainder to the last item
    return Array(itemCount)
      .fill(baseRatio)
      .map((ratio, index) => ratio + (index === itemCount - 1 ? remainder : 0));
  })();

  const [investmentRatios, setInvestmentRatios] =
    useState<number[]>(initialRatios);

  const onRatioChange = (index: number, value: number) => {
    // Calculate the total of all ratios excluding the current slider
    const totalExcludingCurrent = investmentRatios.reduce(
      (acc, curr, i) => acc + (i !== index ? curr : 0),
      0
    );

    // If the new value + total of other sliders <= 100, allow the change
    if (value + totalExcludingCurrent <= 100) {
      const newRatios = [...investmentRatios];
      newRatios[index] = value;
      setInvestmentRatios(newRatios);
    }
    // Otherwise, do not update the state, effectively blocking the slider from moving further
  };

  const onInvestClickInternal = async () => {
    if (totalPercentage !== 100) {
      return;
    }

    if (!customAmount) {
      setError(t("enterAmountError"));
      return;
    }

    setError(undefined);

    // Calculate initial stock amounts based on ratios
    const stockAmounts = customProducts.map((_, index) => {
      const ratio = investmentRatios[index];
      return (Number(customAmount) * ratio) / 100;
    });

    // Round stock amounts and adjust to make sure the total is preserved
    const roundedStockAmounts = stockAmounts.map((amount) =>
      Math.floor(amount)
    );
    const roundedSum = roundedStockAmounts.reduce((acc, curr) => acc + curr, 0);
    let remainder = Number(customAmount) - roundedSum;

    // Distribute the remainder to make the sum of roundedStockAmounts equal to customAmount
    for (let i = 0; remainder > 0 && i < roundedStockAmounts.length; i++) {
      if (stockAmounts[i] - roundedStockAmounts[i] >= 0.5) {
        roundedStockAmounts[i] += 1;
        remainder -= 1;
      }
    }

    // Create the variants with the adjusted stock amounts
    const variantsWithRatios = customProducts.map((product, index) => ({
      variantId: product.id,
      stockAmount: roundedStockAmounts[index],
    }));

    onInvestClick(variantsWithRatios)();
  };

  const maxValues = useMemo(
    () =>
      investmentRatios.map(
        (_, index) =>
          100 -
          investmentRatios.reduce(
            (acc, curr, i) => (i !== index ? acc + curr : acc),
            0
          )
      ),
    [investmentRatios]
  );

  const totalPercentage = useMemo(
    () => investmentRatios.reduce((acc, curr) => acc + curr, 0),
    [investmentRatios]
  );

  const generateSliderMarks = (maxValue: number) => {
    const marks = [];
    const threshold = 15; // Define how close "Max" can be to 0% or 100% before we exclude them

    if (maxValue > threshold) {
      //  marks.push({ value: 0, label: "0%" });
    }

    marks.push({ value: maxValue, label: "Max", style: { color: "blue" } });

    if (100 - maxValue > threshold) {
      //marks.push({ value: 100, label: "100%" });
    }

    return marks;
  };

  return (
    <Stack>
      <Title order={2} mb="md">
        {t("customAmount")}
      </Title>
      <Table verticalSpacing="lg" horizontalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t("productName")}</Table.Th>
            {customProducts.length > 1 && (
              <>
                <Table.Th>
                  <Group gap={6}>
                    {t("investmentRatio")}
                    <Tooltip
                      label={t("investmentRatioTooltip")}
                      position="right"
                      withArrow
                      multiline
                      w={220}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {" "}
                        <IconInfoCircle size={16} />
                      </div>
                    </Tooltip>
                  </Group>
                </Table.Th>
              </>
            )}
            <Table.Th>{t("ratioPercent")}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {customProducts.map((product, index) => (
            <Table.Tr key={product.name}>
              <Table.Td>
                <Text>{product.name}</Text>
              </Table.Td>
              {customProducts.length > 1 && (
                <Table.Td>
                  <Slider
                    mr="xl"
                    h={34}
                    value={investmentRatios[index]}
                    onChange={(value) => onRatioChange(index, value)}
                    min={0}
                    max={100}
                    label={(value) => `${value}%`}
                    step={5}
                    marks={generateSliderMarks(maxValues[index])}
                  />
                </Table.Td>
              )}
              <Table.Td>
                <Badge variant="light" size="lg">
                  {`${investmentRatios[index]}%`}
                </Badge>
                <Text></Text>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      <Group justify="flex-end">
        <NumberInput
          style={{ flex: 0.7 }}
          size="md"
          placeholder={t("enterAmountInEuro")}
          value={customAmount}
          onChange={onAmountChange}
          error={error}
          //max={(investmentGoal - investedAmount) / 100}
          data-testid="amountInput"
        />
        <Text fz="xl">€</Text>
        <Tooltip
          label={t("totalRatioMustBe100", { percentage: totalPercentage })}
          offset={7}
          disabled={totalPercentage === 100}
        >
          <Button
            size="md"
            ml="lg"
            data-disabled={totalPercentage !== 100}
            style={{ flex: 0.3 }}
            onClick={onInvestClickInternal}
            data-testid="investBtn"
          >
            {t("invest")}
          </Button>
        </Tooltip>
      </Group>
    </Stack>
  );
};

export default CustomInvestmentForm;
