import { Container, Paper, SimpleGrid, Title } from "@mantine/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CheckoutStep } from "../components/CheckoutStepper/CheckoutStep";
import CheckoutStepper from "../components/CheckoutStepper/CheckoutStepper";
import CheckoutSummaryContainer from "../components/CheckoutSummary/CheckoutSummary.container";
import StripeCheckoutFormContainer from "../components/StripeCheckoutForm/StripeCheckoutForm.container";

const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey);

const CardPaymentPage = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  return (
    <Container size={900} my={40}>
      <CheckoutStepper
        active={CheckoutStep.PAYMENT}
        customPaymentStepDescription={t("checkout.paymentCardDescription")}
      />
      <Container size={900} my={40}>
        <SimpleGrid
          cols={{
            base: 1,
            sm: 2,
          }}
          spacing="xl"
          mt={48}
        >
          <Paper withBorder shadow="md" p={30} radius="md" flex={1}>
            <Title order={4} mb="md">
              {t("checkout.payByCardForm.title")}
            </Title>
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: state.clientSecret,
                locale: "sk",
                loader: "always",
              }}
            >
              <StripeCheckoutFormContainer orderCode={state.orderCode} />
            </Elements>
          </Paper>
          <CheckoutSummaryContainer />
        </SimpleGrid>
      </Container>
    </Container>
  );
};

export default CardPaymentPage;
