import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import {
  addProductToCartMutationDocument,
  clearCartMutationDocument,
} from "../../api/cart";
import { allProductsQueryDocument } from "../../api/product";
import { ErrorResult } from "../../gql/graphql";
import {
  showGraphQLErrorNotification,
  showErrorNotification,
} from "../../utils";
import InvestmentOptions from "./InvestmentOptions";
import { Skeleton } from "@mantine/core";
import { VariantWithRatio } from "../../types";
import { FC } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  activeOrderQueryDocument,
  setOrderCustomFieldsMutationDocument,
} from "../../api/order";
import { client } from "../../app/apolloClient";

type Props = {
  investmentGoal: number;
  investedAmount: number;
  campaignId: string;
};

const InvestmentOptionsContainer: FC<Props> = ({
  investedAmount,
  investmentGoal,
  campaignId,
}) => {
  const { data, error } = useQuery(allProductsQueryDocument, {
    variables: {
      options: {
        filter: {
          campaignId: { eq: campaignId },
        },
      },
    },
  });

  const navigate = useNavigate();

  const [setCustomFields] = useMutation(setOrderCustomFieldsMutationDocument, {
    onCompleted: (result) => {
      if (result.setOrderCustomFields.__typename === "Order") {
        console.log("Order custom fields set.");
      } else if (
        result.setOrderCustomFields.__typename == "NoActiveOrderError"
      ) {
        showGraphQLErrorNotification(result.setOrderCustomFields);
      } else {
        showErrorNotification("Failed to update order.");
      }
    },
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const [addToCart] = useMutation(addProductToCartMutationDocument, {
    onCompleted: (result) => {
      if (result.addItemToOrder.__typename === "Order") {
        console.log("Item added to cart");
      } else if (
        result.addItemToOrder.__typename === "InsufficientStockError"
      ) {
        notifications.show({
          title: "Error",
          color: "red",
          message: "Insufficient stock",
        });
      } else {
        console.log(result);
        showGraphQLErrorNotification(result.addItemToOrder as ErrorResult);
      }
    },
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const [clearCart] = useMutation(clearCartMutationDocument, {
    onCompleted: () => {},
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const onInvestClick = async (variants: VariantWithRatio[]) => {
    const result = await clearCart();
    if (result.data?.removeAllOrderLines.__typename === "Order") {
      console.log("Cart cleared.");
      const result2 = await setCustomFields({
        variables: {
          input: {
            customFields: {
              campaignId,
            },
          },
        },
      });
      if (result2.data?.setOrderCustomFields.__typename === "Order") {
        console.log("Campaign id set.");
        let code: string | undefined;
        for (const variant of variants) {
          const res = await addToCart({
            variables: {
              productVariantId: variant.variantId,
              quantity: Number(variant.stockAmount),
            },
          });
          if (res.data?.addItemToOrder.__typename === "Order") {
            code = res.data?.addItemToOrder.code;
          }
        }
        if (!code) {
          showErrorNotification("Failed to add produce to cart.");
          return;
        }
        await client.refetchQueries({ include: [activeOrderQueryDocument] });
        navigate("/contact-details", {
          state: { orderCode: code },
        });
      }
    } else {
      if (result.data)
        showGraphQLErrorNotification(result.data?.removeAllOrderLines);
    }
  };

  if (data) {
    return (
      <InvestmentOptions
        products={data.products}
        onInvestClick={onInvestClick}
        investedAmount={investedAmount}
        investmentGoal={investmentGoal}
      />
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  return <Skeleton height={200} />;
};

export default InvestmentOptionsContainer;
